@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

/*input[type="file"] {
    display: none;
  }*/
  .custom-file-upload {
    border: 1px solid white;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    border-radius: 8px;
    background-color: #343b41;
    color: hsla(0, 0%, 65%, 1);
    display: inline-block;
    padding: 6px 10px;
    cursor: pointer;
    width: 240px;
    height: 38px;
  }
  
  .custom-loader {
      width:25px;
      height:25px;
      border-radius:50%;
      background:conic-gradient(#0000 10%, #34d399);
      -webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
      animation:s3 1s infinite linear;
    }
    @keyframes s3 {to{transform: rotate(1turn)}}

.green-background{
    background-color: #005959;
  }

.green-background-hover{
    background-color: #1d4d4d;
  }

.green-light{
    background-color: #46B979;
  }

.green-background-text{
    color: #005959;
  }

.green-light-text{
    color: #46B979;
  }

.black-background{
    background-color: #343b41;
  }

.green{
    color: #5ccfa9;
  };

.black{
  color: #343b41;
}

.aspect-w-19 {
  aspect-ratio: 19 / 9;
}

.aspect-h-9 {
  aspect-ratio: 9 / 19.5;
}



.custom-height {
  height: 600px; /* Sostituisci con l'altezza desiderata */
}


.menu-preview {
  position: absolute;
  top: 10%; /* Regola questi valori per posizionare l'iframe nello schermo */
  left: 5%;
  width: 90%; /* Regola la larghezza per riempire lo schermo dello smartphone nell'immagine */
  height: 80%; /* Regola l'altezza per riempire lo schermo dello smartphone nell'immagine */
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px; /* Larghezza del toggle switch */
  height: 34px; /* Altezza del toggle switch */

}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc; /* Colore di sfondo quando è OFF */
  transition: .4s;
  border-radius: 34px; /* Rendi rotondo il bordo dello sfondo */
}

.switch:before {
  position: absolute;
  content: "";
  height: 26px; /* Altezza del cerchio all'interno del toggle */
  width: 26px; /* Larghezza del cerchio all'interno del toggle */
  left: 4px; /* Posizione iniziale dal lato sinistro */
  bottom: 4px; /* Posizione dal basso */
  background-color: #5ccfa9; /* Colore di sfondo del cerchio */
  transition: .4s;
  border-radius: 50%; /* Rendi il cerchio perfettamente rotondo */
}

input:checked + .switch {
  background-color: #343b41; /* Colore di sfondo quando è ON */
}

input:checked + .switch:before {
  transform: translateX(26px); /* Muovi il cerchio a destra quando è ON */
}

/* Aggiungi stili per lo stato hover su dispositivi con cursore */
/*.switch:hover {
  background-color: #343b41;
}*/

/* Aggiungi stili per lo stato attivo su dispositivi touch */
.switch:active:before {
  width: 30px;
}

.toggle-button {
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #f8f8f8;
  transition: background-color 0.3s ease;
}

.toggle-button.active {
  background-color: #4CAF50; /* Verde */
  color: white;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

